if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  executeDOMScripts();
} else {
  document.addEventListener("DOMContentLoaded", executeDOMScripts);
}

function executeDOMScripts() {
	WebFont.load({
		google: {
			families: ['Open Sans Condensed:300,700']
		}
	});
}